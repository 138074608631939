import React from 'react';
import styled from 'styled-components';

const TableStyles = styled.div`
  padding: var(--margin) 0;

  table {
    border: 1px solid var(--grey-dark);
    table-layout: fixed;
    width: 100%;
  }
  th,
  td {
    border-left: 1px solid var(--grey-dark);
    padding: 10px;
    &:first-child {
      border-left: 0;
    }
  }
  th {
    background-color: var(--grey);
    text-align: left;
  }
  td {
    border-top: 1px solid var(--grey-dark);
  }
`;

export default function Table({ rows }) {
  return (
    <TableStyles>
      <div className="container">
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              {rows[0].cells.map((cell, i) => (
                <th key={`${rows[0]._key}-cell-${i}`}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => {
              if (i === 0) {
                return;
              }
              return (
                <tr key={row._key}>
                  {row.cells.map((cell, j) => (
                    <td key={`${row._key}-cell-${j}`}>{cell}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </TableStyles>
  );
}
