import React from 'react';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import SanityImage from 'gatsby-plugin-sanity-image';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getBlogUrl } from '../../lib/helpers';
import PortableText from '../PortableText';
import { mq } from '../../styles/breakpoints';
import AltThumbnail from '../../assets/images/alt-thumbnail.inline.svg';

const BlogCardStyles = styled.div`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;

  > a {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .img-container {
    background-color: ${(props) => props.thumbBg};
    border-radius: 100%;
    display: block;
    margin: 0 auto var(--margin);
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }

  img {
    border-radius: 100%;
    left: 0;
    max-width: unset;
    position: absolute;
    top: 0;
    width: 100%;

    ${mq[2]} {
      margin-bottom: 0;
    }
  }

  .blog-card__content {
    background: var(--white);
    padding: 0.5rem 1rem;
    text-align: center;

    *:last-child {
      margin-bottom: 0;
    }
  }
  .blog-card__date {
    margin: 0 0 0.5rem;
  }
  .small-title {
    text-align: center;
    /* min-height: 2.625rem; */
  }
  .actu-link {
    align-self: center;
    color: var(--blue-dark);
    margin-top: auto;

    &:hover {
      color: var(--white);
    }
  }
`;

export default function BlogPostPreview(node) {
  const { thumbnail, publishedAt, slug, title, postExcerpt } = node;

  const thumbBg =
    thumbnail?.asset?.metadata?.palette?._rawLightVibrant?.background;
  const thumbRatio = thumbnail?.asset?.metadata?.dimensions?.aspectRatio;
  const ratio = (1 / thumbRatio) * 100;

  return (
    <BlogCardStyles
      className="blog-card"
      thumbBg={thumbBg || '#FF928E'}
      ratio={ratio || 1}
    >
      <Link to={getBlogUrl(publishedAt, slug.current)}>
        {thumbnail ? (
          <div className="img-container">
            <SanityImage
              // pass asset, hotspot, and crop fields
              {...thumbnail}
              // tell Sanity how large to make the image (does not set any CSS)
              height={600}
              width={600}
              alt={thumbnail.asset.altText || title}
              // style it how you want it
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </div>
        ) : (
          <AltThumbnail />
        )}
        <div className="blog-card__content">
          <p className="blog-card__date title-xs">
            {format(parseISO(publishedAt), 'dd MMMM yyyy', { locale: fr })}
          </p>
          <h2 className="title-middle">{title}</h2>
          <PortableText blocks={postExcerpt._rawText} id="excerpt" />
        </div>
        <button type="button" className="button actu-link">
          En savoir plus
        </button>
      </Link>
    </BlogCardStyles>
  );
}
