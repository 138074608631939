import React from 'react';
import styled from 'styled-components';
import BlogPostPreview from './BlogPostPreview';
import { mq } from '../../styles/breakpoints';

const BlogPostsListStyles = styled.div`
  margin-top: 2.75rem;
  position: relative;
  z-index: 3;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      margin-bottom: calc(var(--padding) * 1.5);
      text-align: center;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      ${mq[0]} {
        margin-left: 2.5rem;
        width: calc((100% - 2.5rem) / 2);

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }

      ${mq[2]} {
        width: calc((100% - 5rem) / 3);

        &:nth-child(2n + 1) {
          margin-left: 2.5rem;
        }
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        &:last-child {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
`;

export default function BlogPostPreviewList({ nodes, location, total }) {
  return (
    <>
      <BlogPostsListStyles className="blog-posts-list">
        <ul>
          {nodes &&
            nodes.map((node) => (
              <li key={node.id}>
                <BlogPostPreview {...node} />
              </li>
            ))}
        </ul>
      </BlogPostsListStyles>
    </>
  );
}
