import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { MapToComponents } from 'react-map-to-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers';
import BlogPostPreviewList from '../components/blog/BlogPostPreviewList';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import { mq } from '../styles/breakpoints';
import ThreeColumns from '../components/sections/ThreeColumns';
import TwoColumns from '../components/sections/TwoColumns';
import SingleColumn from '../components/sections/SingleColumn';
import Testimony from '../components/sections/Testimony';
import Hero from '../components/sections/Hero';
import BlockQuestions from '../components/sections/BlockQuestions';
import More from '../components/sections/More';
import Table from '../components/sections/Table';
import TwoColumnsQuery from '../fragments/TwoColumns';
import ThreeColumnsQuery from '../fragments/ThreeColumns';
import SingleColumnQuery from '../fragments/SingleColumn';
import HeroQuery from '../fragments/Hero';
import BlockQuestionsQuery from '../fragments/BlockQuestions';
import MoreQuery from '../fragments/More';
import TableauQuery from '../fragments/Tableau';

const PostListStyles = styled.div`
  padding-top: var(--margin);
  position: relative;

  .section-title {
    margin-bottom: var(--padding);
    text-align: center;
  }
`;

export default function BlogIndex({ data, location, pageContext }) {
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const { title, displayTitle, content, titleSeo, descriptionSeo, image } =
    data?.page;

  return (
    <>
      <SEO
        title={titleSeo || title}
        description={descriptionSeo}
        location={location}
      />
      <PostListStyles>
        {postNodes && (
          <div className="container">
            {image?.asset && (
              <GatsbyImage
                image={getImage(image.asset)}
                alt={image.altText || title}
                className="image-header"
              />
            )}
            <h1 className="section-title">{title}</h1>
            <MapToComponents
              getKey={(section) => section.id || section._key}
              getType={(section) => section._type}
              list={content}
              map={{
                threeColumns: ThreeColumns,
                twoColumns: TwoColumns,
                singleColumn: SingleColumn,
                hero: Hero,
                testimonySection: Testimony,
                blockQuestions: BlockQuestions,
                more: More,
                tableau: Table,
              }}
              mapDataToProps={{
                threeColumns: ({ data }) => ({
                  title: data.title,
                  bgTitle: data.bgTitle?.value,
                  bgSection: data.bgSection?.value,
                  showTitle: data.showTitle,
                  cards: data.cards,
                  titleOptions: data.titleOptions,
                }),
                twoColumns: ({ data }) => ({
                  alignContent: data.alignContent,
                  title: data.title,
                  showTitle: data.showTitle,
                  bgColor: data.bgColor?.value,
                  cards: data.cards,
                  colsProportions: data.colsProportions,
                  ctaButton: data.ctaButton,
                  titleOptions: data.titleOptions,
                }),
                singleColumn: ({ data }) => ({
                  background: data.background?.value,
                  alignCenter: data.alignCenter,
                  ctaButton: data.ctaButton,
                  description: data.columnDescription?._rawText,
                  title: data.title,
                  showTitle: data.showTitle,
                  titleOptions: data.titleOptions,
                }),
                hero: ({ data }) => ({
                  pageTitle: title,
                  images: data.images,
                }),
                steps: ({ data }) => ({
                  title: data.title,
                  steps: data.steps,
                }),
                testimonySection: ({ data }) => ({
                  testimonies: data.testimonyRef,
                  cta: data.cta,
                  ctaBg: data.ctaBg?.value,
                  ctaBgHover: data.ctaBghover?.value,
                  ctaColor: data.ctaColor?.value,
                  ctaColorHover: data.ctaColorHover?.value,
                }),
                blockQuestions: ({ data }) => ({
                  background: data.background?.value,
                  questions: data.questionsList,
                  showTitle: data.showTitle,
                  title: data.title,
                  titleOptions: data.titleOptions,
                }),
                more: ({ data }) => ({
                  text: data.moreText,
                  ctaButton: data.ctaButton,
                }),
                tableau: ({ data }) => ({
                  rows: data._rawDataTable?.rows,
                }),
              }}
            />
            <BlogPostPreviewList
              location={location}
              nodes={postNodes}
              total={data.posts.totalCount}
            />
            {data.posts.totalCount > 12 && (
              <Pagination
                pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
                totalCount={data.posts.totalCount}
                currentPage={pageContext.currentPage || 1}
                base="/actualites"
              />
            )}
          </div>
        )}
      </PostListStyles>
    </>
  );
}

export const query = graphql`
  query BlogIndexQuery($skip: Int = 0, $pageSize: Int = 12) {
    page: sanityPage(slug: { current: { eq: "actualites" } }) {
      title
      displayTitle
      titleMenu
      titleSeo
      descriptionSeo
      image {
        asset {
          altText
          gatsbyImageData(width: 900)
        }
      }
      content {
        ...SingleColumnQuery
        ...TwoColumnsQuery
        ...ThreeColumnsQuery
        ...HeroQuery
        ...MoreQuery
        ...BlockQuestionsQuery
        ...TableauQuery
      }
    }
    posts: allSanityPost(
      limit: $pageSize
      skip: $skip
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      totalCount
      edges {
        node {
          id
          publishedAt
          thumbnail {
            ...Image
            asset {
              altText
              extension
              url
              metadata {
                palette {
                  _rawLightVibrant(resolveReferences: { maxDepth: 10 })
                }
                dimensions {
                  aspectRatio
                }
              }
            }
          }
          image {
            asset {
              altText
              gatsbyImageData(width: 600, height: 600)
            }
          }
          title
          postExcerpt {
            _rawText(resolveReferences: { maxDepth: 10 })
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
