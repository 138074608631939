import { Link } from 'gatsby';
import React from 'react';
import SwiperCore, { Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styled from 'styled-components';
import QuotationMarks from '../../assets/images/quotationMarks.inline.svg';
import BackArrow from '../../assets/images/BackArrow.inline.svg';
import PortableText from '../PortableText';

SwiperCore.use([Pagination, EffectFade, Autoplay]);

const TestimonyStyles = styled.div`
  background: var(--blue);
  color: var(--white);
  font-family: var(--font-titles);
  font-size: 1.0625rem;
  padding: 2.5rem 0;
  position: relative;
  text-align: center;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 41.5rem;
    overflow: hidden;
  }

  .quotation-marks {
    height: 1.0413rem;
  }
  .qm-open {
    margin-right: auto;
  }
  .qm-closed {
    margin: 1.2em 0 0 auto;
    transform: rotate(180deg);
  }

  .author {
    font-size: 0.875rem;
  }
  .place {
    margin-bottom: 1.75rem;
  }

  .link {
    align-items: center;
    color: var(--white);
    display: flex;
    font-size: 0.875rem;
    margin-top: 4rem;

    .arrow {
      height: 0.625rem;
      margin-left: 0.625rem;
      transform: rotate(180deg);

      path {
        stroke: var(--white);
      }
    }
  }

  .button {
    background-color: ${(props) => props.ctaBg || 'transparent'};
    border: 1.5px solid ${(props) => props.ctaColor || 'var(--white)'};
    color: ${(props) => props.ctaColor || 'var(--white)'};

    &:hover {
      background-color: ${(props) => props.ctaBgHover || 'var(--rose)'};
      border: 1.5px solid ${(props) => props.ctaColorHover || 'var(--white)'};
      color: ${(props) => props.ctaColorHover || 'var(--white)'};
    }
  }
`;

const SliderStyles = styled.div`
  margin-bottom: 1.125rem;
  position: relative;
  width: 100%;

  .swiper-container {
    overflow: visible;
    /* width: 100vw; /* absolutely needed! */
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -2.375rem;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }
  .swiper-pagination-bullet {
    background-color: transparent;
    border: 1px solid var(--blue-dark);
    height: 0.75rem;
    opacity: 1;
    width: 0.75rem;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--blue-dark);
  }
`;

const Testimony = ({
  testimonies,
  cta,
  ctaBg,
  ctaBgHover,
  ctaColor,
  ctaColorHover,
}) => {
  console.log(ctaColorHover);
  return (
    <TestimonyStyles
      ctaBg={ctaBg}
      ctaBgHover={ctaBgHover}
      ctaColor={ctaColor}
      ctaColorHover={ctaColorHover}
    >
      <div className="container">
        <SliderStyles>
          <Swiper
            className="testimonay-slider"
            spaceBetween={0}
            slidesPerView={1}
            slidesPerGroup={1}
            speed={700}
            grabCursor
            effect="fade"
            fadeEffect={{ crossFade: true }}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
            }}
          >
            {testimonies.map((testimony, index) => (
              <SwiperSlide key={`slide-testimony-${index}`} className="slide">
                <QuotationMarks className="quotation-marks qm-open" />
                {testimony.testimonyExcerpt && (
                  <PortableText blocks={testimony.testimonyExcerpt._rawText} />
                )}
                <QuotationMarks className="quotation-marks qm-closed" />
                <p className="author place">
                  {testimony.author} / {testimony.place}
                </p>
                <Link
                  to={`/temoignages/${testimony.slug.current}`}
                  className="button"
                >
                  {cta}
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderStyles>
        <Link to="/temoignages" className="link">
          Voir tous les témoignagnes
          <BackArrow className="arrow" />
        </Link>
      </div>
    </TestimonyStyles>
  );
};

export default Testimony;
